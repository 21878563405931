.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__button__container{
    display: flex;
    align-items: center;
    justify-content: center;
}


/* ====================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ====================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

/* PORTFOLIO-PAGE */
.portfolio__page > a.portfolio__page_back{
    margin: 25px 0 0 25px;
}

/* .portfolio__page_main {
    position: relative;
    width: 100%;
    margin: 0rem auto;
}*/

.portfolio__page_social {
    top: 15rem;
    left: 13rem;
    position: absolute;
    width: 15%;
}

.portfolio__page_list {
    width: 75%;
} 
.portfolio__page_main {
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem auto;
}
