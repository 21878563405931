header {
    /* height: 92vh !important; 
    overflow: hidden;*/
    margin-top: 7rem;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ====================== CTA ================== */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ====================== HEADER SOCIALS ================== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 1rem;
    background: var(--color-primary);
}

/* ====================== ME ================== */
.conatiner__me{
    display: flex;
    justify-content: center;
}
.me {
    background:linear-gradient(to bottom, #F8FCF5,#F8FBEF ,#DAE3D9) ;
    width: 22rem;
    height: 17rem;
    /* position: absolute; */
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 4rem 1.5rem 1.5rem 1.5rem;
}

/* ====================== SCROLL DOWN ================== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ====================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    /* header {
        height: 92vh !important;
    } */
}

/* ====================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    /* header {
        height: 100vh !important;
    } */

    .header__socials,
    .scroll__down {
        display: none;
    }
}